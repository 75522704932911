<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-4">
                <h5 class="mb-0">Tarifas Adicionales</h5>
              </div>
              <div class="col-sm-8">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Tarifas Adicionales</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <!-- Tarjeta de filtros -->
              <div
                class="col-md-12 col-sm-12"
                :class="
                  $store.getters.can('hidrocarburos.standby.VerCosto')
                    ? 'col-lg-9 '
                    : 'col-lg-12'
                "
              >
                <div class="card">
                  <div class="card-header pt-2 pb-2">
                    <div class="row">
                      <div class="col-md-6">
                        <h5><i class="fas fa-filter mr-2"></i> Filtros</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            @click="limpiar"
                          >
                            <i class="fas fa-broom"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-success"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.DescargarExcel'
                              )
                            "
                            @click="generarListadoExcel()"
                          >
                            <i class="far fa-file-excel"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-success"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.DescargarExcelTransportadora'
                              )
                            "
                            @click="generarListadoExcelTransportadora()"
                          >
                            <i class="far fa-file-excel"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-0">
                    <div class="direct-chat-messages p-1" style="height: 280px">
                      <div id="accordion">
                        <!-- Acordeón de Detalles -->
                        <div class="card card-gray-dark mb-1">
                          <div class="card-header">
                            <a
                              data-toggle="collapse"
                              href="#collapse-detalles"
                              aria-expanded="false"
                              class="d-block w-100 collapsed"
                            >
                              <h4 class="card-title w-100">
                                <i class="fas fa-search mr-2"></i> Detalles
                              </h4>
                            </a>
                          </div>
                          <div
                            id="collapse-detalles"
                            data-parent="#accordion"
                            class="collapse"
                          >
                            <div class="card-body">
                              <div class="row">
                                <div
                                  class="form-group col-md-3"
                                  v-if="
                                    $store.getters.can(
                                      'hidrocarburos.standby.VerCosto'
                                    )
                                  "
                                >
                                  <div class="icheck-primary d-inline">
                                    <input
                                      type="checkbox"
                                      id="generacosto"
                                      :checked="filtros.genera_costo"
                                      v-model="filtros.genera_costo"
                                      @change="getIndex()"
                                    />
                                    <label for="generacosto">
                                      Generan Costo
                                    </label>
                                  </div>
                                </div>
                                <div
                                  class="form-group col-md-9"
                                  v-if="
                                    $store.getters.can(
                                      'hidrocarburos.standby.VerCosto'
                                    )
                                  "
                                >
                                  <div class="icheck-primary d-inline">
                                    <input
                                      type="checkbox"
                                      id="generacostoParametros"
                                      :checked="filtros.genera_costo_parametros"
                                      v-model="filtros.genera_costo_parametros"
                                      @change="getIndex()"
                                    />
                                    <label for="generacostoParametros">
                                      Generan Costo Parámteros
                                    </label>
                                  </div>
                                </div>
                                <div class="form-group col-md-1">
                                  <label>N° Viaje</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="filtros.viaje"
                                    @input="getIndex()"
                                  />
                                </div>
                                <div class="form-group col-md-1">
                                  <label>N° Guía</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="filtros.guia"
                                    @input="getIndex()"
                                  />
                                </div>
                                <div class="form-group col-md-2">
                                  <label>Tipo Tarifa Adicional</label>
                                  <select
                                    class="form-control form-control-sm"
                                    v-model="filtros.tipo_standby"
                                    @change="getIndex()"
                                  >
                                    <option value="">Seleccione...</option>
                                    <option
                                      v-for="estado in listasForms.tipo_standby"
                                      :key="estado.numeracion"
                                      :value="estado.numeracion"
                                    >
                                      {{ estado.descripcion }}
                                    </option>
                                  </select>
                                </div>
                                <div class="form-group col-md-4">
                                  <label>Transportadora</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="filtros.razon_social"
                                    @input="getIndex()"
                                  />
                                </div>
                                <div class="form-group col-md-2">
                                  <label>Tip. Combustible</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="filtros.tipoCombustible"
                                    placeholder="Combustible"
                                    label="descripcion"
                                    :options="listasForms.tiposCombustible"
                                    style="
                                      font-size: 13px;
                                      background-color: #fff;
                                    "
                                    @input="getIndex()"
                                  ></v-select>
                                </div>
                                <div class="form-group col-md-2">
                                  <label>Vehículo</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="filtros.placa"
                                    @input="getIndex()"
                                  />
                                </div>
                                <div class="form-group col-md-4">
                                  <label>Producto</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="filtros.producto"
                                    @input="getIndex()"
                                  />
                                </div>
                                <div class="form-group col-md-4">
                                  <label>Sitio Cargue</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="filtros.sitio_cargue"
                                    @input="getIndex()"
                                  />
                                </div>
                                <div class="form-group col-md-4">
                                  <label>Sitio Descargue</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="filtros.sitio_descargue"
                                    @input="getIndex()"
                                  />
                                </div>
                                <div
                                  class="form-group col-md-3"
                                  v-if="
                                    $store.getters.can(
                                      'hidrocarburos.standby.VerCosto'
                                    )
                                  "
                                >
                                  <label>Valor sin Parámetros</label>
                                  <input
                                    type="number"
                                    class="form-control form-control-sm"
                                    v-model="filtros.valor_sin_parametros"
                                    @input="getIndex()"
                                  />
                                </div>
                                <div
                                  class="form-group col-md-9"
                                  v-if="
                                    $store.getters.can(
                                      'hidrocarburos.standby.VerCosto'
                                    )
                                  "
                                >
                                  <label for="novedades">Novedades</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="filtros.novedades"
                                    label="descripcion"
                                    :options="listasForms.novedades"
                                    style="
                                      font-size: 13px;
                                      background-color: #fff;
                                    "
                                    placeholder="Novedades"
                                    multiple
                                    @input="getIndex()"
                                  ></v-select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Acordeón de Estados -->
                        <div class="card card-gray-dark mb-1">
                          <div class="card-header">
                            <a
                              data-toggle="collapse"
                              href="#collapse-estados"
                              aria-expanded="false"
                              class="d-block w-100 collapsed"
                            >
                              <h4 class="card-title w-100">
                                <i class="fas fa-tasks mr-2"></i> Estados
                              </h4>
                            </a>
                          </div>
                          <div
                            id="collapse-estados"
                            data-parent="#accordion"
                            class="collapse"
                          >
                            <div class="card-body">
                              <div class="row">
                                <div class="form-group col-md-4">
                                  <label>Estado Cita</label>
                                  <select
                                    class="form-control form-control-sm"
                                    v-model="filtros.estado_enturne"
                                    @change="getIndex()"
                                  >
                                    <option value="">Seleccione...</option>
                                    <option
                                      v-for="estado in listasForms.estados_enturnes"
                                      :key="estado.numeracion"
                                      :value="estado.numeracion"
                                    >
                                      {{ estado.descripcion }}
                                    </option>
                                  </select>
                                </div>
                                <div class="form-group col-md-4">
                                  <label>Estado Novedades</label>
                                  <select
                                    class="form-control form-control-sm"
                                    v-model="filtros.estado_novedades"
                                    @change="getIndex()"
                                  >
                                    <option value="">Seleccione...</option>
                                    <option
                                      v-for="estado in listasForms.estados_novedades"
                                      :key="estado.numeracion"
                                      :value="estado.numeracion"
                                    >
                                      {{ estado.descripcion }}
                                    </option>
                                  </select>
                                </div>
                                <div class="form-group col-md-4">
                                  <label>Estado</label>
                                  <select
                                    class="form-control form-control-sm"
                                    v-model="filtros.estado"
                                    @change="getIndex()"
                                  >
                                    <option value="">Seleccione...</option>
                                    <option
                                      v-for="estado in listasForms.estados"
                                      :key="estado.numeracion"
                                      :value="estado.numeracion"
                                    >
                                      {{ estado.descripcion }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Acordeón de Fechas -->
                        <div
                          v-if="
                            $store.getters.can('hidrocarburos.standby.VerCosto')
                          "
                          class="card card-gray-dark mb-1"
                        >
                          <div class="card-header">
                            <a
                              data-toggle="collapse"
                              href="#collapse-fechas"
                              aria-expanded="false"
                              class="d-block w-100 collapsed"
                            >
                              <h4 class="card-title w-100">
                                <i class="fas fa-calendar mr-2"></i> Fechas
                              </h4>
                            </a>
                          </div>
                          <div
                            id="collapse-fechas"
                            data-parent="#accordion"
                            class="collapse"
                          >
                            <div class="card-body">
                              <div class="row">
                                <div class="col-md-6">
                                  <strong>Cargue</strong>
                                  <div class="row">
                                    <div class="col-md-6">
                                      <small>Fecha Inicial</small>
                                      <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="filtros.fecha_inicio_cargue"
                                        @input="validaFechasFiltroCargue()"
                                      />
                                    </div>
                                    <div class="col-md-6">
                                      <small>Fecha Final</small>
                                      <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="filtros.fecha_fin_cargue"
                                        @input="validaFechasFiltroCargue()"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <strong>Descargue</strong>
                                  <div class="row">
                                    <div class="col-md-6">
                                      <small>Fecha Inicial</small>
                                      <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="filtros.fecha_inicio_descargue"
                                        @input="validaFechasFiltroDescargue()"
                                      />
                                    </div>
                                    <div class="col-md-6">
                                      <small>Fecha Final</small>
                                      <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="filtros.fecha_fin_descargue"
                                        @input="validaFechasFiltroDescargue()"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Tarjeta de Resumen de valores -->
              <div
                class="col-lg-3 col-md-12 col-sm-12"
                v-if="$store.getters.can('hidrocarburos.standby.VerCosto')"
              >
                <div class="card">
                  <div class="card-header pt-2 pb-2">
                    <h5>Resumen</h5>
                  </div>
                  <div class="card-body p-0">
                    <div class="direct-chat-messages" style="height: 280px">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="small-box bg-navy mb-1">
                            <div class="inner">
                              <h3 style="font-size: 2em">Cargues</h3>
                              <h3 style="font-size: 1.2em">
                                Sin Parámetros:
                                <small>{{
                                  informe.cargue.sin_parametros
                                }}</small>
                              </h3>
                              <h3 style="font-size: 1.2em">
                                Con Parámetros:
                                <small>{{
                                  informe.cargue.con_parametros
                                }}</small>
                              </h3>
                            </div>
                            <div class="icon">
                              <i class="ion ion-pie-graph"></i>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="small-box bg-info mb-1">
                            <div class="inner">
                              <h3 style="font-size: 2em">Descargues</h3>
                              <h3 style="font-size: 1.2em">
                                Sin Parámetros:
                                <small>{{
                                  informe.descargue.sin_parametros
                                }}</small>
                              </h3>
                              <h3 style="font-size: 1.2em">
                                Con Parámetros:
                                <small>{{
                                  informe.descargue.con_parametros
                                }}</small>
                              </h3>
                            </div>
                            <div class="icon">
                              <i class="ion ion-pie-graph"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Tarjeta de tabla de contenido -->
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-md-8">
                        <h5 class="mb-0">Detalles</h5>
                      </div>
                      <div class="col-md-4">
                        <div class="btn-group col-md-3 float-right">
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            data-toggle="modal"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.DescargarComprobantes'
                              )
                            "
                            data-target="#Modal_standby"
                            @click="getDatos(null, 'Comprobantes')"
                          >
                            <i class="fas fa-file-pdf"></i>
                            <br /><small>Comprobantes</small>
                          </button>
                        </div>
                        <div class="btn-group col-md-3 float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.ActualizarStandby'
                              )
                            "
                            data-target="#Modal_standby"
                            @click="getDatos(null, 'Actualizar')"
                          >
                            <i class="fas fa-sync-alt"></i>
                            <br /><small>Recalcular</small>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body table-responsive p-0">
                    <table
                      id="users"
                      style="font-size: 0.8em"
                      class="table table-bordered table-striped table-hover table-sm text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Viaje</th>
                          <th>Guía</th>
                          <th>Vencimiento Guía</th>
                          <th>Transportadora</th>
                          <th>Vehículo</th>
                          <th>Producto</th>
                          <th>Sitio Cargue</th>
                          <th>Sitio Descargue</th>
                          <th
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            Tiempo Liquidado
                          </th>
                          <th
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            Valor sin Parámetros
                          </th>
                          <th
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            Valor con Parámetros
                          </th>
                          <th
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            Fecha Enturne
                          </th>
                          <th
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            Fecha Máxima Cargue/Descargue
                          </th>
                          <th
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            Fecha Cargue/Descargue
                          </th>
                          <th
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            Salida Campo
                          </th>
                          <th
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            Tiempo Vigencia
                          </th>
                          <th
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            Tiempo Cobrado
                          </th>
                          <th
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            Valor Cobrado
                          </th>
                          <th>Fecha Enturne Empresa</th>
                          <th>Fecha cargue/Descargue Empresa</th>
                          <th>#Aprobación</th>
                          <th>Valor Pagado</th>
                          <th>Cita</th>
                          <th>Novedades</th>
                          <th>Estado</th>
                          <th>Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="standby in standbys.data" :key="standby.id">
                          <td class="text-center">
                            <span
                              class="badge"
                              :class="
                                standby.tipo == 1 ? 'bg-navy' : 'badge-info'
                              "
                              >{{ standby.nTipoStandby }}</span
                            >
                          </td>
                          <td class="text-center">{{ standby.viaje_id }}</td>
                          <td class="text-center">
                            {{ standby.viaje.guia.numero }}-{{
                              standby.viaje.guia.digito_verificacion
                            }}
                          </td>
                          <td class="text-center">
                            {{
                              standby.viaje.trazas_fechas_viaje.fecha_fin_guia
                            }}
                          </td>
                          <td>
                            {{
                              standby.empresa
                                ? standby.empresa.razon_social
                                : ""
                            }}
                          </td>
                          <td class="text-center">
                            {{ standby.viaje.vehiculo.placa }}
                            <div>
                              <span
                                v-if="standby.viaje.vehiculo != null"
                                class="badge ml-1 mr-1"
                                :class="
                                  standby.viaje.vehiculo.tipo_combustible == 1
                                    ? 'badge-warning'
                                    : 'badge-info'
                                "
                              >
                                {{ standby.viaje.vehiculo.nCombustible }}
                              </span>
                            </div>
                          </td>
                          <td>{{ standby.viaje.producto_liquido.nombre }}</td>
                          <td>{{ standby.viaje.sitio_cargue.nombre }}</td>
                          <td>{{ standby.viaje.sitio_descargue.nombre }}</td>
                          <td
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            <div>{{ standby.dias_liqui + " días" }}</div>
                            <div>{{ standby.horas_liqui + " horas" }}</div>
                          </td>
                          <td
                            class="text-right"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            <strong>{{
                              standby.currency_valor_liquidado
                            }}</strong>
                          </td>
                          <td
                            class="text-right"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            <strong>{{
                              standby.currency_valor_liquidado_parametros
                            }}</strong>
                          </td>
                          <td
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            {{
                              standby.tipo == 1
                                ? standby.viaje.trazas_fechas_viaje
                                    .fecha_enturne_cargue
                                : standby.viaje.trazas_fechas_viaje
                                    .fecha_enturne_descargue
                            }}
                          </td>
                          <td
                            class="text-center"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            {{ standby.fecha_maxima }}
                          </td>
                          <td
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            {{
                              standby.tipo == 1
                                ? standby.viaje.trazas_fechas_viaje.fecha_cargue
                                : standby.viaje.trazas_fechas_viaje
                                    .fecha_descargue
                            }}
                          </td>
                          <td
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            {{ standby.viaje.guia.det_guias[0].fecha_cargue }}
                            {{ standby.viaje.guia.det_guias[0].hora_cargue }}
                          </td>
                          <td
                            class="text-center"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            {{
                              standby.viaje.guia.det_guias[0].horas_vigencia +
                                standby.viaje.guia.det_guias[0].hora_adicional
                            }}
                          </td>
                          <td
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            <div>
                              {{ standby.dias_liqui_propuesto + " días" }}
                            </div>
                            <div>
                              {{ standby.horas_liqui_propuesto + " horas" }}
                            </div>
                          </td>
                          <td
                            class="text-right"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.standby.VerCosto'
                              )
                            "
                          >
                            <strong>{{
                              standby.currency_valor_propuesto
                            }}</strong>
                          </td>
                          <td>
                            {{
                              standby.tipo == 1
                                ? standby.viaje.trazas_fechas_viaje
                                    .fecha_enturne_cargue_propuesto
                                : standby.viaje.trazas_fechas_viaje
                                    .fecha_enturne_descargue_propuesto
                            }}
                          </td>
                          <td>
                            {{
                              standby.tipo == 1
                                ? standby.viaje.trazas_fechas_viaje
                                    .fecha_cargue_propuesto
                                : standby.viaje.trazas_fechas_viaje
                                    .fecha_fin_viaje_propuesto
                            }}
                          </td>
                          <td>{{ standby.num_aceptacion }}</td>
                          <td>{{ standby.standby_pagado }}</td>
                          <td class="text-center">
                            <span
                              class="badge"
                              :class="
                                standby.estado_enturne == 1
                                  ? 'badge-success'
                                  : 'badge-danger'
                              "
                              >{{ standby.nEstadoEnturne }}</span
                            >
                          </td>
                          <td class="text-center">
                            <span
                              class="badge"
                              :class="
                                standby.estado_novedades == 1
                                  ? 'badge-success'
                                  : 'badge-danger'
                              "
                              >{{ standby.nEstadoNovedad }}</span
                            >
                          </td>
                          <td class="text-center">
                            <span
                              class="badge"
                              :class="
                                standby.estado == 1
                                  ? 'badge-secondary'
                                  : standby.estado == 2
                                  ? 'badge-warning'
                                  : standby.estado == 3
                                  ? 'bg-navy'
                                  : standby.estado == 4
                                  ? 'bg-orange'
                                  : standby.estado == 5
                                  ? 'badge-info'
                                  : standby.estado == 6
                                  ? 'badge-danger'
                                  : standby.estado == 7
                                  ? 'badge-success'
                                  : 'bg-light'
                              "
                              >{{ standby.nEstado }}</span
                            >
                          </td>
                          <td style="width: 50px">
                            <div class="btn-group float-left">
                              <button
                                type="button"
                                class="btn btn-sm btn-info"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.standby.index'
                                  )
                                "
                                data-toggle="modal"
                                data-target="#Modal_standby"
                                @click="getDatos(standby, 'Detalles')"
                              >
                                <i class="fas fa-eye"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                v-if="
                                  ($store.getters.can(
                                    'hidrocarburos.standby.ProponerValores'
                                  ) &&
                                    (standby.estado == 1 || // Generado
                                      standby.estado == 2)) || // Por Validar
                                  ($store.getters.can(
                                    'hidrocarburos.standby.AutorizarPagos'
                                  ) &&
                                    (standby.estado == 3 || // Cobrado
                                      standby.estado == 4)) || // Cobrado por Validar
                                    ($store.getters.can(
                                      'hidrocarburos.standby.RealizarPagos'
                                    ) &&
                                      standby.estado == 5) // Pago Autorizado
                                "
                                data-toggle="modal"
                                data-target="#Modal_standby"
                                @click="getDatos(standby, 'Editar')"
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-sm btn-primary"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.standby.ActualizarStandby'
                                  ) &&
                                    (standby.estado == 1 ||
                                      standby.estado == 2 ||
                                      standby.estado == 3 ||
                                      standby.estado == 4)
                                "
                                @click="
                                  actualizarStandby(standby.id, standby.tipo)
                                "
                              >
                                <i class="fa fa-sync-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="card-footer">
                    <div class="float-left" v-if="standbys.total">
                      <p>
                        Mostrando del <b>{{ standbys.from }}</b> al
                        <b>{{ standbys.to }}</b> de un total:
                        <b>{{ standbys.total }}</b> Registros
                      </p>
                    </div>
                    <div class="float-left" v-else>
                      <p>
                        <span class="badge badge-danger">
                          No hay registros para mostrar
                        </span>
                      </p>
                    </div>
                    <pagination
                      :data="standbys"
                      @pagination-change-page="getIndex"
                      :limit="5"
                      class="float-right"
                    ></pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <!-- Modal de standby -->
      <div class="modal fade" id="Modal_standby">
        <div
          class="modal-dialog"
          :class="
            modalType == 'Editar' ||
            modalType == 'Actualizar' ||
            modalType == 'Comprobantes'
              ? 'modal-md'
              : 'modal-lg'
          "
        >
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4
                class="modal-title text-white"
                v-if="modalType == 'Comprobantes'"
              >
                {{ modalType }}
              </h4>
              <h4 class="modal-title text-white" v-else>
                {{ modalType }} Tarifa Adicional {{ accion }}
              </h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- Formulario para recalcular el standby -->
            <div
              class="modal-body"
              v-if="
                modalType == 'Actualizar' &&
                  $store.getters.can('hidrocarburos.standby.ActualizarStandby')
              "
            >
              <div class="row">
                <div class="form-group col-md-6">
                  <label>Fecha Inicial</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="form.fecha_inicial"
                    :class="
                      $v.form.fecha_inicial.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="validaFechasRecalcular"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Fecha Final</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="form.fecha_final"
                    :class="
                      $v.form.fecha_final.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="validaFechasRecalcular"
                  />
                </div>
              </div>
            </div>
            <!-- Vista de los detalles del standby -->
            <div
              class="modal-body"
              v-if="modalType == 'Detalles'"
              style="font-size: 0.85em"
            >
              <!-- Información del Standby y sus estados -->
              <div class="position-relative p-3 m-2 bg-light">
                <div class="ribbon-wrapper ribbon-lg">
                  <div
                    class="ribbon"
                    :class="
                      datosStandby.estado == 1
                        ? 'badge-secondary'
                        : datosStandby.estado == 2
                        ? 'badge-warning'
                        : datosStandby.estado == 3
                        ? 'bg-navy'
                        : datosStandby.estado == 4
                        ? 'bg-orange'
                        : datosStandby.estado == 5
                        ? 'badge-info'
                        : datosStandby.estado == 6
                        ? 'badge-danger'
                        : datosStandby.estado == 7
                        ? 'badge-success'
                        : 'badge-danger'
                    "
                  >
                    <small>{{ datosStandby.nEstado }}</small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="description-block">
                      <h5
                        class="description-header"
                        :class="
                          datosStandby.estado_enturne == 1
                            ? 'text-success'
                            : 'text-danger'
                        "
                      >
                        {{ datosStandby.nEstadoEnturne }}
                      </h5>
                      <span class="description-text text-secondary"
                        >ESTADO CITA</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="description-block">
                      <h5
                        class="description-header"
                        :class="
                          datosStandby.estado_novedades == 1
                            ? 'text-success'
                            : 'text-danger'
                        "
                      >
                        {{ datosStandby.nEstadoNovedad }}
                      </h5>
                      <span class="description-text text-secondary"
                        >ESTADO NOVEDADES</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-6" v-if="datosStandby.num_aceptacion">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.num_aceptacion }}
                      </h5>
                      <span class="description-text text-secondary"
                        ># Aceptación</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-6" v-if="datosStandby.num_aceptacion">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.standby_pagado }}
                      </h5>
                      <span class="description-text text-secondary"
                        >Valor Aceptado</span
                      >
                    </div>
                  </div>
                  <!-- tabla de observaciones -->
                  <div
                    class="col-sm-12"
                    v-if="
                      $store.getters.can('hidrocarburos.standby.VerCosto') &&
                        datosStandby.observaciones.length > 0
                    "
                  >
                    <div class="description-block">
                      <table class="table table-sm table-striped table-hover">
                        <thead class="thead-dark">
                          <tr>
                            <th>Estado</th>
                            <th>Observación</th>
                            <th>Usuario</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="observacion in datosStandby.observaciones"
                            :key="observacion.id"
                          >
                            <td class="text-center">
                              <span
                                class="badge"
                                :class="
                                  observacion.estado_standby == 1
                                    ? 'badge-secondary'
                                    : observacion.estado_standby == 2
                                    ? 'badge-warning'
                                    : observacion.estado_standby == 3
                                    ? 'bg-navy'
                                    : observacion.estado_standby == 4
                                    ? 'bg-orange'
                                    : observacion.estado_standby == 5
                                    ? 'badge-info'
                                    : observacion.estado_standby == 6
                                    ? 'badge-danger'
                                    : observacion.estado_standby == 7
                                    ? 'badge-success'
                                    : 'badge-danger'
                                "
                                >{{ observacion.nEstado }}</span
                              >
                            </td>
                            <td class="text-left">
                              {{ observacion.observacion }}
                            </td>
                            <td class="text-left">
                              {{ observacion.user.name }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Información del Viaje -->
              <div class="position-relative p-3 m-2 bg-light">
                <div class="ribbon-wrapper ribbon-sm">
                  <div class="ribbon bg-info">Viaje</div>
                </div>
                <div class="row">
                  <div class="col-sm-3">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.viaje_id }}
                      </h5>
                      <span class="description-text text-secondary">VIAJE</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.empresa.razon_social }}
                      </h5>
                      <span class="description-text text-secondary"
                        >EMPRESA</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.viaje.vehiculo.placa }}
                      </h5>
                      <span class="description-text text-secondary"
                        >VEHÍCULO</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- Información de Novedades -->
              <div
                class="position-relative p-3 m-2 bg-light"
                v-if="
                  ($store.getters.can('hidrocarburos.standby.VerCosto') &&
                    datosStandby.novedades_empresa.length > 0) ||
                    ($store.getters.can(
                      'hidrocarburos.standby.ProponerValores'
                    ) &&
                      datosStandby.novedades_transportadora.length > 0)
                "
              >
                <div class="ribbon-wrapper ribbon-lg">
                  <div class="ribbon bg-info">Novedades</div>
                </div>
                <!-- Tabla para Frontera -->
                <div
                  class="row"
                  v-if="
                    $store.getters.can('hidrocarburos.standby.VerCosto') &&
                      datosStandby.novedades_empresa.length > 0
                  "
                >
                  <table class="table table-sm table-striped table-hover">
                    <thead class="thead-dark">
                      <tr>
                        <th>Bloqueante</th>
                        <th>Novedad</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="novedades in datosStandby.novedades_empresa"
                        :key="novedades.id"
                      >
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="
                              novedades.cod_alterno == 'SI'
                                ? 'badge-danger'
                                : 'badge-success'
                            "
                            >{{ novedades.cod_alterno }}</span
                          >
                        </td>
                        <td>
                          {{ novedades.descripcion }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- Tabla para transportadora -->
                <div
                  class="row"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.standby.ProponerValores'
                    ) && datosStandby.novedades_transportadora.length > 0
                  "
                >
                  <table class="table table-sm table-striped table-hover">
                    <thead class="thead-dark">
                      <tr>
                        <th>Bloqueante</th>
                        <th>Novedad</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="novedades in datosStandby.novedades_transportadora"
                        :key="novedades.id"
                      >
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="
                              novedades.cod_alterno == 'SI'
                                ? 'badge-danger'
                                : 'badge-success'
                            "
                            >{{ novedades.cod_alterno }}</span
                          >
                        </td>
                        <td>
                          {{ novedades.descripcion }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- Información de las fechas y liquidación del sistema -->
              <!-- Información que debe tener filtros -->
              <div
                class="position-relative p-3 m-2 bg-light"
                v-if="$store.getters.can('hidrocarburos.standby.VerCosto')"
              >
                <div class="ribbon-wrapper ribbon-sm">
                  <div class="ribbon bg-info">Fechas</div>
                </div>
                <div class="row" v-if="datosStandby.tipo == 1">
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.viaje.trazas_fechas_viaje.fecha_solicitud
                        }}
                      </h5>
                      <span class="description-text text-secondary">CITA</span>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.viaje.trazas_fechas_viaje
                            .fecha_enturne_cargue
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >ENTURNE CARGUE</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.viaje.trazas_fechas_viaje.fecha_cargue
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >FECHA CARGUE</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.fecha_cobrado }}
                      </h5>
                      <span class="description-text text-secondary"
                        >FECHA COBRADO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.fecha_autorizado }}
                      </h5>
                      <span class="description-text text-secondary"
                        >FECHA AUTORIZADO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.fecha_aprobado }}
                      </h5>
                      <span class="description-text text-secondary"
                        >FECHA APROBADO</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row" v-if="datosStandby.tipo == 2">
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.viaje.trazas_fechas_viaje.fecha_fin_guia
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >VENCIMIENTO GUÍA</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.viaje.trazas_fechas_viaje
                            .fecha_enturne_descargue
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >ENTURNE DESCARGUE</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.estado == 5
                            ? datosStandby.viaje.guia.cierre_guias[0]
                                .fecha_hora_descargue
                            : datosStandby.viaje.trazas_fechas_viaje
                                .fecha_fin_viaje
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >FECHA DESCARGUE</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.fecha_cobrado }}
                      </h5>
                      <span class="description-text text-secondary"
                        >FECHA COBRADO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.fecha_autorizado }}
                      </h5>
                      <span class="description-text text-secondary"
                        >FECHA AUTORIZADO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.fecha_aprobado }}
                      </h5>
                      <span class="description-text text-secondary"
                        >FECHA APROBADO</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="position-relative p-4 m-2 bg-light"
                v-if="$store.getters.can('hidrocarburos.standby.VerCosto')"
              >
                <div class="ribbon-wrapper ribbon-lg">
                  <div class="ribbon bg-info">Liquidación</div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.tarifa_adicional_dia_base }}
                      </h5>
                      <span class="description-text text-secondary"
                        >TARIFA ADICIONAL BASE DÍA</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.tarifa_adicional_hora_base }}
                      </h5>
                      <span class="description-text text-secondary"
                        >TARIFA ADICIONAL BASE HORA</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.currency_valor_liquidado }}
                      </h5>
                      <span class="description-text text-secondary"
                        >VALOR LIQUIDADO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.tiempo_contrato }}
                      </h5>
                      <span class="description-text text-secondary"
                        >TIEMPO {{ text_accion }} CONTRATO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.tiempo_total }} horas
                      </h5>
                      <span class="description-text text-secondary"
                        >TIEMPO TOTAL</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.tiempo_liquidado }} horas
                      </h5>
                      <span class="description-text text-secondary"
                        >TIEMPO LIQUIDADO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.dias_liqui }}
                      </h5>
                      <span class="description-text text-secondary"
                        >DÍAS LIQUIDADOS</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.horas_liqui }}
                      </h5>
                      <span class="description-text text-secondary"
                        >HORAS LIQUIDADAS</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- Fin - Información que debe tener filtros -->

              <!-- Información de los cobrado por la empresa -->
              <div
                class="position-relative p-3 m-2 bg-light"
                v-if="datosStandby.currency_valor_propuesto"
              >
                <div class="ribbon-wrapper ribbon-lg">
                  <div class="ribbon bg-info">Cobro Empresa</div>
                </div>
                <div class="row" v-if="datosStandby.tipo == 1">
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.viaje.trazas_fechas_viaje.fecha_solicitud
                        }}
                      </h5>
                      <span class="description-text text-secondary">CITA</span>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.viaje.trazas_fechas_viaje
                            .fecha_enturne_cargue_propuesto
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >ENTURNE CARGUE</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.viaje.trazas_fechas_viaje
                            .fecha_cargue_propuesto
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >FECHA CARGUE</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row" v-if="datosStandby.tipo == 2">
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.viaje.trazas_fechas_viaje.fecha_fin_guia
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >VENCIMIENTO GUÍA</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.viaje.trazas_fechas_viaje
                            .fecha_enturne_descargue_propuesto
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >ENTURNE DESCARGUE</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.viaje.trazas_fechas_viaje
                            .fecha_fin_viaje_propuesto
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >FECHA DESCARGUE</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  v-if="$store.getters.can('hidrocarburos.standby.VerCosto')"
                >
                  <div class="col-sm-3">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.currency_valor_propuesto
                            ? datosStandby.currency_valor_propuesto
                            : "Sin asignar"
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >VALOR PROPUESTO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          datosStandby.tiempo_propuesto
                            ? `${datosStandby.tiempo_propuesto} horas`
                            : "Sin asignar"
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >TIEMPO PROPUESTO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.dias_liqui_propuesto }}
                      </h5>
                      <span class="description-text text-secondary"
                        >DÍAS PROPUESTOS</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ datosStandby.horas_liqui_propuesto }}
                      </h5>
                      <span class="description-text text-secondary"
                        >HORAS PROPUESTAS</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- formulario para cambiar estado del standby -->
            <div
              class="modal-body"
              v-if="
                modalType == 'Editar' &&
                  $store.getters.can('hidrocarburos.standby.edit')
              "
            >
              <div class="row">
                <div class="form-group col-md-12">
                  <label>Estado</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.estado"
                    :class="$v.form.estado.$invalid ? 'is-invalid' : 'is-valid'"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="estado in listasForms.estadosForm"
                      :key="estado.numeracion"
                      :value="estado.numeracion"
                    >
                      {{ estado.descripcion }}
                    </option>
                  </select>
                </div>
                <div
                  class="form-group col-md-12"
                  v-if="
                    flagNovedades &&
                      listasForms.novedades &&
                      (this.datosStandby.estado == 3 ||
                        this.datosStandby.estado == 4)
                  "
                >
                  <label for="novedades">Novedades</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="form.novedades"
                    label="descripcion"
                    :options="listasForms.novedades"
                    placeholder="Novedades"
                    multiple
                  ></v-select>
                </div>
                <div
                  class="form-group col-md-12"
                  v-if="this.datosStandby.estado == 5"
                >
                  <label># de Acepatción</label>
                  <input
                    type="text"
                    maxlength="10"
                    class="form-control form-control-sm"
                    v-model="form.num_aceptacion"
                    :class="
                      $v.form.num_aceptacion.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @blur="validaAceptacion()"
                  />
                </div>
                <div
                  class="form-group col-md-12"
                  v-if="this.datosStandby.estado == 5"
                >
                  <label>Standby a Pagar</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    v-model="form.standby_pagado"
                    :class="
                      $v.form.standby_pagado.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    readonly
                  />
                </div>
                <div class="form-group col-md-12">
                  <label>Observación</label>
                  <textarea
                    class="form-control form-control-sm"
                    v-model="form.observacion"
                    :class="
                      $v.form.observacion.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  ></textarea>
                </div>
              </div>
            </div>
            <!-- Formulario para proponer valores -->
            <div
              class="modal-body"
              v-if="
                modalType == 'Editar' &&
                  $store.getters.can('hidrocarburos.standby.ProponerValores')
              "
            >
              <div class="row">
                <div class="form-group col-md-6">
                  <label>Fecha Enturne {{ accion }}</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="form.fecha_enturne"
                    :class="
                      $v.form.fecha_enturne.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="validaFechas"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Hora Enturne {{ accion }}</label>
                  <!-- <input
                    type="time"
                    class="form-control form-control-sm"
                    v-model="form.hora_enturne"
                    :class="
                      $v.form.hora_enturne.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="validaFechas"
                  /> -->
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form.hora_enturne"
                    :class="
                      $v.form.hora_enturne.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '24',
                    }"
                    @input="validaFechas"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Fecha {{ accion }}</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="form.fecha_accion"
                    :class="
                      $v.form.fecha_accion.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="validaFechas"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Hora {{ accion }}</label>
                  <!-- <input
                    type="time"
                    class="form-control form-control-sm"
                    v-model="form.hora_accion"
                    :class="
                      $v.form.hora_accion.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="validaFechas"
                  /> -->
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form.hora_accion"
                    :class="
                      $v.form.hora_accion.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '24',
                    }"
                    @input="validaFechas"
                  />
                </div>
              </div>
            </div>
            <!-- Formulario para descargar comprobantes -->
            <div
              class="modal-body"
              v-if="
                modalType == 'Comprobantes' &&
                  $store.getters.can(
                    'hidrocarburos.standby.DescargarComprobantes'
                  )
              "
            >
              <div class="row">
                <div class="form-group col-md-12">
                  <label>Tipo Standby</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="formComprobante.tipo_standby"
                    :class="
                      $v.formComprobante.tipo_standby.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="estado in listasForms.tipo_standby"
                      :key="estado.numeracion"
                      :value="estado.numeracion"
                    >
                      {{ estado.descripcion }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-12">
                  <label># Aceptación</label>
                  <input
                    type="text"
                    maxlength="10"
                    class="form-control form-control-sm"
                    v-model="formComprobante.num_aceptacion"
                    :class="
                      $v.formComprobante.num_aceptacion.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
              </div>
            </div>
            <!-- Footer para botones -->
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                v-if="
                  modalType == 'Actualizar' &&
                    $store.getters.can(
                      'hidrocarburos.standby.ActualizarStandby'
                    ) &&
                    !$v.form.$invalid
                "
                @click="actualizarStandby()"
              >
                Recalcular
              </button>
              <button
                type="button"
                class="btn btn-primary"
                v-if="
                  modalType == 'Editar' &&
                    $store.getters.can('hidrocarburos.standby.edit') &&
                    !$v.form.$invalid
                "
                @click="save(`Frontera`)"
              >
                Gestionar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                v-if="
                  modalType == 'Editar' &&
                    $store.getters.can('hidrocarburos.standby.ProponerValores')
                "
                @click="save(`Transportadora`)"
              >
                Proponer
              </button>
              <button
                type="button"
                class="btn btn-primary"
                v-if="
                  modalType == 'Comprobantes' &&
                    $store.getters.can(
                      'hidrocarburos.standby.DescargarComprobantes'
                    ) &&
                    !$v.formComprobante.$invalid
                "
                @click="getComprobante"
              >
                Descargar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import pagination from "laravel-vue-pagination";
import {
  required,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";
import { VBPopover } from "bootstrap-vue";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "StandbyIndex",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    pagination,
    vSelect,
  },
  data() {
    return {
      cargando: true,
      standbys: {},
      datosStandby: {},
      datos_excel: {},
      limite_registros_descarga: 9000,
      informe: {
        cargue: {},
        descargue: {},
      },
      modalType: "",
      filtros: {
        genera_costo: false,
        genera_costo_parametros: false,
        viaje: null,
        guia: null,
        tipo_standby: null,
        razon_social: null,
        placa: null,
        tipoCombustible: null,
        producto: null,
        sitio_cargue: null,
        sitio_descargue: null,
        valor_sin_parametros: null,
        novedades: [],
        estado_enturnes: null,
        estado_novedades: null,
        estado: null,
        fecha_inicio_cargue: null,
        fecha_fin_cargue: null,
        fecha_inicio_descargue: null,
        fecha_fin_descargue: null,
      },
      listasForms: {
        tipo_standby: {},
        estados_enturne: {},
        tiposCombustible: [],
        estados_novedades: {},
        estados: {},
        estadosForm: {},
        novedades: [],
      },
      form: {},
      formComprobante: {},
      accion: null, // Si es de cargue o descargue
      text_accion: null, // Si es de cargue o descargue
      flagNovedades: false,
      flagPagos: false,
    };
  },

  validations() {
    // Se valida el formulario de actualizacion de standbys
    if (
      this.$store.getters.can("hidrocarburos.standby.ActualizarStandby") &&
      !this.datosStandby
    ) {
      if (
        this.$store.getters.can("hidrocarburos.standby.DescargarComprobantes")
      ) {
        return {
          form: {
            fecha_inicial: {
              required,
            },
            fecha_final: {
              required,
            },
          },
          formComprobante: {
            tipo_standby: {
              required,
            },
            num_aceptacion: {
              required,
              minLength: minLength(10),
              maxLength: maxLength(10),
            },
          },
        };
      } else {
        return {
          form: {
            fecha_inicial: {
              required,
            },
            fecha_final: {
              required,
            },
          },
        };
      }
    }

    // Se valida el formulario de propsición de standby
    if (
      this.$store.getters.can("hidrocarburos.standby.ProponerValores") &&
      this.datosStandby
    ) {
      if (this.datosStandby.estado == 1 || this.datosStandby.estado == 2) {
        if (
          this.$store.getters.can("hidrocarburos.standby.DescargarComprobantes")
        ) {
          return {
            form: {
              id: {
                required,
              },
              viaje_id: {
                required,
              },
              fecha_enturne: {
                required,
              },
              hora_enturne: {
                required,
              },
              fecha_accion: {
                required,
              },
              hora_accion: {
                required,
              },
              fecha_fin_guia: {
                required,
              },
            },
            formComprobante: {
              tipo_standby: {
                required,
              },
              num_aceptacion: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
              },
            },
          };
        } else {
          return {
            form: {
              id: {
                required,
              },
              viaje_id: {
                required,
              },
              fecha_enturne: {
                required,
              },
              hora_enturne: {
                required,
                timeFormat,
              },
              fecha_accion: {
                required,
              },
              hora_accion: {
                required,
                timeFormat,
              },
              fecha_fin_guia: {
                required,
              },
            },
          };
        }
      }
    }

    // Se valida los formularios para fontera
    if (this.$store.getters.can("hidrocarburos.standby.edit")) {
      // Formulario de autorización o negación de un pago
      if (this.datosStandby.estado == 3 || this.datosStandby.estado == 4) {
        if (
          this.$store.getters.can("hidrocarburos.standby.DescargarComprobantes")
        ) {
          return {
            form: {
              id: {
                required,
              },
              estado: {
                required,
              },
              observacion: {
                required,
                minLength: minLength(10),
              },
            },
            formComprobante: {
              tipo_standby: {
                required,
              },
              num_aceptacion: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
              },
            },
          };
        } else {
          return {
            form: {
              id: {
                required,
              },
              estado: {
                required,
              },
              observacion: {
                required,
                minLength: minLength(10),
              },
            },
          };
        }
      }

      // Formulario de registro de pago del standby
      if (this.datosStandby.estado == 5) {
        if (
          this.$store.getters.can("hidrocarburos.standby.DescargarComprobantes")
        ) {
          return {
            form: {
              id: {
                required,
              },
              estado: {
                required,
              },
              num_aceptacion: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
              },
              standby_pagado: {
                required,
              },
              observacion: {
                required,
                minLength: minLength(10),
              },
            },
            formComprobante: {
              tipo_standby: {
                required,
              },
              num_aceptacion: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
              },
            },
          };
        } else {
          return {
            form: {
              id: {
                required,
              },
              estado: {
                required,
              },
              num_aceptacion: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
              },
              standby_pagado: {
                required,
              },
              observacion: {
                required,
                minLength: minLength(10),
              },
            },
          };
        }
      }

      if (
        this.$store.getters.can("hidrocarburos.standby.DescargarComprobantes")
      ) {
        return {
          formComprobante: {
            tipo_standby: {
              required,
            },
            num_aceptacion: {
              required,
              minLength: minLength(10),
              maxLength: maxLength(10),
            },
          },
        };
      }
    }

    // Se valida el formulario de decarga e aprobaciones
    if (
      this.$store.getters.can("hidrocarburos.standby.DescargarComprobantes")
    ) {
      return {
        formComprobante: {
          tipo_standby: {
            required,
          },
          num_aceptacion: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
        },
      };
    }
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      if (this.$store.getters.can("hidrocarburos.standby.edit")) {
        this.filtros.frontera = true;
      }
      axios
        .get("/api/hidrocarburos/standby?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.getData(response.data.paginate);
          this.getInforme(response.data.informe);
          this.cargando = false;
        })
        .catch(function(error) {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getData(standby) {
      standby.data.forEach((element) => {
        element.tarifa_adicional_dia = element.tarifa_adicional_dia_base;
        element.tarifa_adicional_hora = element.tarifa_adicional_hora_base;

        // Se filtra la visualizacion de los estados para la transportadora
        if (this.$store.getters.can("hidrocarburos.standby.ProponerValores")) {
          if (element.estado == 1 || element.estado == 2) {
            element.nEstado = "";
          }
          if (element.estado == 4) {
            element.estado = 3;
            element.nEstado = "Cobrado";
          }
        }

        // Se buscan las novedades que tenga el standby
        let novedades_empresa = new Array();
        let novedades_transportadora = new Array();
        if (element.tipo == 1) {
          if (element.viaje.guia.det_guias.length > 0) {
            element.viaje.guia.det_guias[0].novedades.forEach(function(
              novedad
            ) {
              //if (novedad.numeracion != 1) {
              novedades_empresa.push(novedad);
              if (novedad.cod_alterno == "SI") {
                novedades_transportadora.push(novedad);
              }
              //}
            });
          }
        } else {
          if (element.viaje.guia.cierre_guias.length > 0) {
            element.viaje.guia.cierre_guias[0].novedades.forEach(function(
              novedad
            ) {
              //if (novedad.numeracion != 1) {
              novedades_empresa.push(novedad.novedad);
              if (novedad.cod_alterno == "SI") {
                novedades_transportadora.push(novedad.novedad);
              }
              //}
            });
          }
        }

        element.novedades_empresa = novedades_empresa;
        element.novedades_transportadora = novedades_transportadora;

        // Se asignan y castean las variables a mostrar
        if (element.tipo == 1) {
          const fecha_cita = element.viaje.trazas_fechas_viaje.fecha_solicitud;
          const fecha_enturne =
            element.viaje.trazas_fechas_viaje.fecha_enturne_cargue;
          if (fecha_enturne > fecha_cita) {
            element.fecha_maxima = moment(fecha_enturne)
              .add(element.tiempo_contrato, "hours")
              .format("YYYY-MM-DD HH:mm:ss");
          } else {
            element.fecha_maxima = moment(fecha_cita)
              .add(element.tiempo_contrato, "hours")
              .format("YYYY-MM-DD HH:mm:ss");
          }
        } else {
          element.fecha_maxima = moment(
            element.viaje.trazas_fechas_viaje.fecha_fin_guia
          )
            .add(element.tiempo_contrato, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
        }
        element.currency_valor_liquidado = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          element.valor_liquidado
        );
        element.valor_liquidado_parametros = element.valor_liquidado;
        if (element.estado_enturne != 1 || element.estado_novedades != 1) {
          element.valor_liquidado_parametros = 0;
        }
        element.currency_valor_liquidado_parametros = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          element.valor_liquidado_parametros
        );
        element.tarifa_adicional_dia_base = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          element.tarifa_adicional_dia_base
        );
        element.tarifa_adicional_hora_base = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          element.tarifa_adicional_hora_base
        );
        //if (element.standby_pagado > 0) {
        element.standby_pagado = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          element.standby_pagado
        );
        /* } else {
          element.standby_pagado = "";
        } */
        //if (element.valor_propuesto > 0) {
        element.currency_valor_propuesto = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          element.valor_propuesto
        );
        //}
      });
      this.standbys = standby;
    },

    getInforme(informe) {
      this.informe = {};
      let valor_cargue_sin_parametros = 0;
      let valor_cargue_con_parametros = 0;
      let valor_descargue_sin_parametros = 0;
      let valor_descargue_con_parametros = 0;

      informe.sin_parametros.forEach((sin) => {
        switch (sin.tipo) {
          case 1:
            valor_cargue_sin_parametros = sin.VALOR;
            break;

          case 2:
            valor_descargue_sin_parametros = sin.VALOR;
            break;
        }
      });

      informe.con_parametros.forEach((con) => {
        switch (con.tipo) {
          case 1:
            valor_cargue_con_parametros = con.VALOR;
            break;

          case 2:
            valor_descargue_con_parametros = con.VALOR;
            break;
        }
      });

      this.informe = {
        cargue: {
          sin_parametros: this.formatCurrency(
            "es-CO",
            "COP",
            2,
            valor_cargue_sin_parametros
          ),
          con_parametros: this.formatCurrency(
            "es-CO",
            "COP",
            2,
            valor_cargue_con_parametros
          ),
        },
        descargue: {
          sin_parametros: this.formatCurrency(
            "es-CO",
            "COP",
            2,
            valor_descargue_sin_parametros
          ),
          con_parametros: this.formatCurrency(
            "es-CO",
            "COP",
            2,
            valor_descargue_con_parametros
          ),
        },
      };
    },

    getTipoStandby() {
      axios.get("/api/lista/64").then((response) => {
        this.listasForms.tipo_standby = response.data;
      });
    },

    getEstadosEnturnes() {
      axios.get("/api/lista/60").then((response) => {
        this.listasForms.estados_enturnes = response.data;
      });
    },

    getTiposCombustibles() {
      axios.get("/api/lista/24").then((response) => {
        this.listasForms.tiposCombustible = response.data;
      });
    },

    getEstadosNovedades() {
      axios.get("/api/lista/61").then((response) => {
        this.listasForms.estados_novedades = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/62").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getEstadosForm(estados) {
      axios
        .get("/api/hidrocarburos/lista/estados?filtro=" + estados)
        .then((response) => {
          this.listasForms.estadosForm = response.data;
        });
    },

    getNovedades() {
      axios.get("/api/lista/52").then((response) => {
        this.listasForms.novedades = response.data;
      });
    },

    getDatos(standby = null, modalType) {
      this.datosStandby = standby;
      this.modalType = modalType;
      this.formSave = {};
      this.flagNovedades = false;
      this.flagPagos = false;

      // Formulario de actualización de standbys
      if (modalType == "Actualizar") {
        this.form = {
          fecha_inicial: null,
          fecha_final: null,
        };
      }

      if (standby) {
        this.accion = `(${standby.nTipoStandby})`;
        this.text_accion = standby.nTipoStandby;
      }

      // Formularios de edición de la gestión del standby
      if (modalType == "Editar") {
        // Se filtra las opciones para el select de estado del standby y formularios
        let estados = null;
        // Formulario para proposición de la Transportadora
        if (standby.estado == 1 || standby.estado == 2) {
          let ahora = new Date();
          let fecha_format = `${ahora.getFullYear()}-${ahora.getMonth() +
            1}-${ahora.getDate()}`;
          let hora_format = `${ahora.getHours()}:${ahora.getMinutes()}:${ahora.getSeconds()}`;
          this.form = {
            id: standby.id,
            tipo: standby.tipo, // Si es de cargue o descargue
            viaje_id: standby.viaje_id,
            empresa_id: standby.empresa_id,
            transportadora: standby.empresa.razon_social,
            tarifa_contrato_hidrocarburo_id:
              standby.tarifa_contrato_hidrocarburo_id,
            fecha_cobrado: fecha_format + " " + hora_format,
            det_tarifa_id: standby.det_tarifa_id,
            tarifa_adicional_dia: standby.tarifa_adicional_dia,
            tarifa_adicional_hora: standby.tarifa_adicional_hora,
            traza_fecha_viaje_id: standby.viaje.trazas_fechas_viaje.id,
            estado: standby.estado,
            fecha_hora_enturne: null,
            fecha_hora_accion: null,
            num_guia: standby.viaje.guia.numero,
            placa: standby.viaje.vehiculo.placa,
            fecha_fin_guia: standby.viaje.trazas_fechas_viaje.fecha_fin_guia,
          };
        }

        // Formulario apara aprobacion o rechazo de lo propuesto por la transportadora
        if (standby.estado == 3 || standby.estado == 4) {
          let ahora = new Date();
          let fecha_format = `${ahora.getFullYear()}-${ahora.getMonth() +
            1}-${ahora.getDate()}`;
          let hora_format = `${ahora.getHours()}:${ahora.getMinutes()}:${ahora.getSeconds()}`;
          this.form = {
            id: standby.id,
            estado: null,
            guia_id: standby.viaje.guia_id,
            novedades: [],
            observacion: standby.observacion,
            tipo_standby: standby.tipo, // Si es de cargue o descargue
            viaje_id: standby.viaje_id,
            fecha_autorizado: fecha_format + " " + hora_format,
            transportadora: standby.empresa.razon_social,
            empresa_id: standby.empresa_id,
            flag_envio_email: true,
            num_guia: standby.viaje.guia.numero,
            placa: standby.viaje.vehiculo.placa,
          };

          // Estado "Cobrado" y "Cobrado por valiar"
          estados = [5, 6];
          this.flagNovedades = true;
        }

        // Formulario para el registro del pago del standby
        if (standby.estado == 5) {
          // Estado "Pago autorizado"
          estados = [7];
          this.flagPagos = true;

          // Se valida el valor menor de los cálculos entre el valor del sistema y el valor propueesto por la transportadora
          let standby_pagado = null;
          if (
            parseFloat(standby.valor_liquidado) <
            parseFloat(standby.valor_propuesto)
          ) {
            standby_pagado = standby.valor_liquidado;
          } else {
            standby_pagado = standby.valor_propuesto;
          }
          let ahora = new Date();
          let fecha_format = `${ahora.getFullYear()}-${ahora.getMonth() +
            1}-${ahora.getDate()}`;
          let hora_format = `${ahora.getHours()}:${ahora.getMinutes()}:${ahora.getSeconds()}`;
          this.form = {
            id: standby.id,
            estado: null,
            num_aceptacion: "",
            standby_pagado,
            observacion: standby.observacion,
            tipo_standby: standby.tipo, // Si es de cargue o descargue
            viaje_id: standby.viaje_id,
            fecha_aprobado: fecha_format + " " + hora_format,
            transportadora: standby.empresa.razon_social,
            empresa_id: standby.empresa_id,
            flag_envio_email: true,
            num_guia: standby.viaje.guia.numero,
            placa: standby.viaje.vehiculo.placa,
          };
        }

        if (standby.estado == 7) {
          estados = [7];
        }

        this.getEstadosForm(estados);
      }

      // Formulario de actualización de standbys
      if (modalType == "Comprobantes") {
        this.formComprobante = {
          num_aceptacion: null,
          tipo_standby: null,
        };
      }
    },

    actualizarStandby(id = null, tipo = null) {
      this.accion = "";
      let flag_actualizar = false;
      if (id) {
        this.form = { id, tipo };
        flag_actualizar = true;
      } else if (!this.$v.form.$invalid) {
        flag_actualizar = true;
      }

      if (flag_actualizar) {
        this.cargando = true;
        axios({
          method: "PUT",
          url: "/api/hidrocarburos/standby/actualizarStandby",
          data: this.form,
        })
          .then(() => {
            this.$refs.closeModal.click();
            this.form = {};
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se ha actualizado exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch(() => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ha ocurrido un error, intentelo nuevamente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    save(peticion) {
      if (!this.$v.form.$invalid) {
        let url = null;

        switch (peticion) {
          case `Frontera`:
            this.formSave = this.form;
            url = "/api/hidrocarburos/standby";
            break;

          case `Transportadora`:
            this.formSave = {
              id: this.form.id,
              viaje_id: this.form.viaje_id,
              empresa_id: this.form.empresa_id,
              transportadora: this.form.transportadora,
              tipo_standby: this.form.tipo,
              traza_fecha_viaje_id: this.form.traza_fecha_viaje_id,
              estado: this.form.estado,
              tarifa_contrato_hidrocarburo_id: this.form
                .tarifa_contrato_hidrocarburo_id,
              det_tarifa_id: this.form.det_tarifa_id,
              tarifa_adicional_dia: this.form.tarifa_adicional_dia,
              tarifa_adicional_hora: this.form.tarifa_adicional_hora,
              num_guia: this.form.num_guia,
              placa: this.form.placa,
              fecha_fin_guia: this.form.fecha_fin_guia,
            };
            if (this.form.tipo == 1) {
              // Si es de cargue
              this.formSave.fecha_enturne_cargue_propuesto = `${this.form.fecha_enturne} ${this.form.hora_enturne}`;
              this.formSave.fecha_cargue_propuesto = `${this.form.fecha_accion} ${this.form.hora_accion}`;
            } else {
              // Si es de descargue
              this.formSave.fecha_enturne_descargue_propuesto = `${this.form.fecha_enturne} ${this.form.hora_enturne}`;
              this.formSave.fecha_fin_viaje_propuesto = `${this.form.fecha_accion} ${this.form.hora_accion}`;
            }
            url = "/api/hidrocarburos/standby/ProponerValores";
            break;

          default:
            break;
        }

        if (this.formSave) {
          this.cargando = true;
          axios({
            method: "PUT",
            url,
            data: this.formSave,
          })
            .then((response) => {
              if (!response.data.error) {
                this.$refs.closeModal.click();
                this.formSave = {};
                this.getIndex();
                this.$swal({
                  icon: "success",
                  title: `Se ha gestionado el standby de ${this.accion} exitosamente...`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: response.data.error,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
              this.cargando = false;
            })
            .catch(() => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ha ocurrido un error, intentelo nuevamente",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      }
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formatted = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);
      return formatted;
    },

    validaFechas() {
      if (
        this.form.fecha_enturne &&
        this.form.hora_enturne &&
        this.form.fecha_accion &&
        this.form.hora_accion
      ) {
        const fecha_menor = new Date(
          `${this.form.fecha_enturne} ${this.form.hora_enturne}`
        );
        const fecha_mayor = new Date(
          `${this.form.fecha_accion} ${this.form.hora_accion}`
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_accion = null;
          this.form.hora_accion = null;
          this.$swal({
            icon: "error",
            title: `La "Fecha Hora Enturne ${this.accion}" no puede ser mayor a la "Fecha Hora ${this.accion}"...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },

    // Validacion de fechas de cargue del filtro
    validaFechasFiltroCargue() {
      if (this.filtros.fecha_inicio_cargue && this.filtros.fecha_fin_cargue) {
        const fecha_menor = new Date(this.filtros.fecha_inicio_cargue);
        const fecha_mayor = new Date(this.filtros.fecha_fin_cargue);

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_fin_cargue = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicial no puede ser mayor a la Fecha Final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.getIndex();
        }
      } else {
        this.getIndex();
      }
    },

    // Validacion de fechas de cargue del filtro
    validaFechasFiltroDescargue() {
      if (
        this.filtros.fecha_inicio_descargue &&
        this.filtros.fecha_fin_descargue
      ) {
        const fecha_menor = new Date(this.filtros.fecha_inicio_descargue);
        const fecha_mayor = new Date(this.filtros.fecha_fin_descargue);

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_fin_descargue = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicial no puede ser mayor a la Fecha Final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.getIndex();
        }
      } else {
        this.getIndex();
      }
    },

    validaFechasRecalcular() {
      if (this.form.fecha_inicial && this.form.fecha_final) {
        const fecha_menor = new Date(this.form.fecha_inicial);
        const fecha_mayor = new Date(this.form.fecha_final);

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La "Fecha Inicial" no puede ser mayor a la "Fecha Final"...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaAceptacion() {
      if (this.form.num_aceptacion) {
        this.cargando = true;
        let form = {};

        if (this.form.tipo_standby == 1) {
          // Es de cargue
          form = {
            tipo_standby: this.form.tipo_standby,
            num_aceptacion: this.form.num_aceptacion,
            empresa_id: this.form.empresa_id,
            sitio_cargue_id: this.datosStandby.viaje.sitio_cargue_id,
          };
        } else {
          // Es de descargue
          form = {
            tipo_standby: this.form.tipo_standby,
            num_aceptacion: this.form.num_aceptacion,
            empresa_id: this.form.empresa_id,

            sitio_descargue_id: this.datosStandby.viaje.sitio_descargue_id,
          };
        }

        axios
          .get("/api/hidrocarburos/standby/validarAceptacion", {
            params: form,
          })
          .then((response) => {
            this.cargando = false;

            if (!response.data.flag) {
              this.$swal({
                icon: "error",
                title: `El número de aceptación ${this.form.num_aceptacion} no puede ser asignado. ${response.data.msg}`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
              this.form.num_aceptacion = null;
            }
          })
          .catch(() => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ha ocurrido un error, intentelo nuevamente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getComprobante() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/standby/verPDF",
        data: this.formComprobante,
      })
        .then((response) => {
          let data = response.data;
          if (data.msj) {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: data.msj,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.cargando = false;
            window.open(data.url, "_blank");
          }
        })
        .catch(() => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error, intentelo nuevamente",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    limpiar() {
      this.filtros = {
        viaje: null,
        guia: null,
        tipo_standby: null,
        razon_social: null,
        placa: null,
        producto: null,
        sitio_cargue: null,
        sitio_descargue: null,
        valor_sin_parametros: null,
        estado_enturnes: null,
        estado_novedades: null,
        estado: null,
        cargue_fecha_enturne: null,
        cargue_hora_enturne: null,
        cargue_fecha: null,
        cargue_hora: null,
        descargue_fecha_enturne: null,
        descargue_hora_enturne: null,
        descargue_fecha: null,
        descargue_hora: null,
      };
      this.getIndex();
    },

    generarListadoExcel() {
      if (this.standbys.total < this.limite_registros_descarga) {
        this.cargando = true;
        axios({
          method: "POST",
          url: "/api/hidrocarburos/standby/export",
          data: this.filtros,
        })
          .then((response) => {
            let data = response.data;
            location.href = data.url;
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error - " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      } else {
        this.$swal({
          icon: "error",
          title: `Hay demasiados registros para descargar, por favor filtre la información a descargar. Límite máximo: ${this.limite_registros_descarga}`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    },

    generarListadoExcelTransportadora() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/standby/export/transportadora",
        data: this.filtros,
      })
        .then((response) => {
          let data = response.data;
          location.href = data.url;
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  mounted() {
    this.getIndex();
    this.getTipoStandby();
    this.getEstadosEnturnes();
    this.getTiposCombustibles();
    this.getEstadosNovedades();
    this.getEstados();
    this.getNovedades();
  },
};
</script>
